import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as ProductExplorer } from "assets/icons/product-exlorer.svg";
import { ReactComponent as StoreFinder } from "assets/icons/store-finder-shopify.svg";
import { ShopifyPlanList } from "types/plans";

export const shopifyPlans: ShopifyPlanList[] = [
  {
    type: "ShopProM",
    name: "Monthly",
    id: "monthly",
    skipAndSave: 15,
    isDiscount: false,
    description: "Scan, Search and Track as much as you want!",
    price: 29.99,
    discountedPrice: 29.99,
    isYearly: false,
  },
  {
    type: "ShopProY",
    name: "Yearly",
    id: "yearly",
    isDiscount: true,
    skipAndSave: 15,
    description: "Scan, Search and Track as much as you want!",
    price: 299,
    discountedPrice: 199,
    isYearly: true,
  },
];

export const shopifyPlanDescription = [
  {
    title: "Market Insights",
    desc: "Get instant access to winning products, top stores, and upcoming trends all-in-one dashboard",
    id: "01",
    Icon: Logo,
    width: 17.5,
    height: 17.5,
  },
  {
    title: "Sales Tracker",
    desc: "Conduct limitless Shopify competitor research by simply copying and pasting store URLs",
    id: "02",
    Icon: CompetitorResearch,
    width: 15,
    height: 16.25,
  },
  {
    title: "Product Explorer",
    desc: "Instantly uncover winning products with a quick search",
    id: "03",
    Icon: ProductExplorer,
    width: 17.5,
    height: 18.75,
  },
  {
    title: "Store Finder",
    desc: "Identify leading Shopify stores and reveal their best-selling products",
    id: "04",
    Icon: StoreFinder,
    width: 16.58335,
    height: 19.13465,
  },
];

export const shopifyPlanComparison = [
  {
    title: "Market Insights",
    desc: "Find the best products to maximize sales.",
    id: "01",
    Icon: Logo,
    width: 14,
    height: 14,
  },
  {
    title: "Sales Tracker",
    desc: "Get current intel on competitors products and strategies.",
    id: "02",
    Icon: CompetitorResearch,
    width: 12,
    height: 13,
  },
  {
    title: "Product Explorer",
    desc: "Know and use all the keywords currently driving traffic + sales.",
    id: "03",
    Icon: ProductExplorer,
    width: 14,
    height: 15,
  },
  {
    title: "Store Finder",
    desc: "Identify leading Shopify stores and reveal their best-selling products",
    id: "04",
    Icon: StoreFinder,
    width: 13.27,
    height: 15.31,
  },
];
