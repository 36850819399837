import { shopifyPlans } from "assets/data/plans/shopifyPlans";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import Button from "components/Button";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { onSelectPlan } from "store/slices/planSlice";
import { TSelectedPlan } from "types/plans";
import { formatCurrency } from "utils/formatCurrency";
import { makeQueryParams } from "utils/makeQueryParams";

interface ItemProps {
    price: number;
    name: string;
    id: string;
    isYearly: boolean;
    skipAndSave: number;
    type: string;
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    isPayNow?: boolean;
    discountedPrice: number;
    fromNoSub?: boolean;
}
interface Props {
    isRegFunnel?: boolean;
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    isPayNow?: boolean;
    hiddenCol?: "monthly" | "yearly"
    fromNoSub?: boolean;
}

const FooterItemContent: FC<ItemProps> = ({ price, type, name, skipAndSave, isYearly, discountedPrice, handleSelectPlan, fromNoSub, isPayNow }) => {
    const navigate = useNavigate();
    const user = useDecodeToken();
    const dispatch = useAppDispatch();
    const hasNoDiscount = !isYearly && isPayNow
    const isUpgrade = isYearly && user.shopify_package_name === "ShopProM";
    const isCurrent = (isYearly && user.shopify_package_name === "ShopProY") || (!isYearly && user.shopify_package_name === "ShopProM");
    const skipDollarTrial = isPayNow ? "Pay Now" : "Start $1 Trial Now"
    const skipDollarStyles = `mb-2 bg-white mx-auto max-sm:max-w-[100%] ${isYearly ? "max-w-[244px]" : "max-w-[206px]"}`
    const skipTitleStyles = "!text-base dark:text-darkBlue dark:group-hover:text-white"
    const handleClick = (isSkip?: boolean) => {
        const plan = {
            type: type,
            planName: name,
            planPrice: price,
            skip: isSkip || false

        }
        if (handleSelectPlan) {
            handleSelectPlan(plan);
        } else {
            navigate(makeQueryParams(PATH.managementPanel.subscriptionPurchase, {
                isShopify: true,
                type: plan.type,
                ...(isSkip && { skip: true })
            }));
            dispatch(onSelectPlan(plan));

        }
    }
    return (!isPayNow && isCurrent) ? <div className="text-center text-sm font-medium">This is your Current Plan</div> : (
        <div className="flex flex-col">
            <h5 className="text-xl font-bold max-md:text-center max-lg:hidden">{name}</h5>
            <div className={`flex items-center max-md:flex-col items-start justify-between text-xs mb-2 max-lg:mb-1 ${fromNoSub ? "max-lg:text-white" : ""} ${isYearly ? "max-md:mb-1" : ""}`}>
                <h6 className="text-lg max-lg:mx-auto font-bold">{formatCurrency(hasNoDiscount ? price : discountedPrice)}<span className="font-normal">{isYearly ? "/yr" : "/mo"}</span></h6>
                {isYearly ? (
                    <div className="flex items-center font-medium">
                        <span className="text-[#FF6A00] text-sm">Save ${discountedPrice && (price - discountedPrice)}!</span>
                        <div className="flex w-[22px] justify-center">
                            <Checkmark className="fill-smoothGreen w-[11px] h-[8px]" />
                        </div>
                    </div>
                ) : ""}
            </div>
            {isUpgrade ? (
                <Button title="Upgrade Now" className={`mb-[15px] bg-white mx-auto max-w-[206px] max-sm:max-w-[146px] mt-2 max-sm:!mb-1`} redirect={PATH.shopify.upgrade} isLink titleClassName={skipTitleStyles} color="smoothGreen" height="h-11" />
            ) : (
                <div className="flex flex-col items-center justify-center">
                    <Button title={skipDollarTrial} className={skipDollarStyles} titleClassName={skipTitleStyles} color="smoothGreen" height="h-11" handleClick={() => handleClick()} />
                    {isPayNow ? "" : (
                        <button className={`text-center underline text-xs ${fromNoSub ? "max-lg:!text-smoothGreen" : ""} ${isYearly ? "text-electricBlue lg:text-smoothGreen " : "text-electricBlue"}`} onClick={() => handleClick(true)}> Skip Trial {skipAndSave === 0 ? "Now!" : `and Save ${formatCurrency(skipAndSave)}`}</button>
                    )}
                </div>
            )}
        </div>
    )
}
const ShopifyPlanComparisonFooter: FC<Props> = ({ isRegFunnel, handleSelectPlan, isPayNow, hiddenCol, fromNoSub }) => {
    const user = useDecodeToken();
    const currentYearly = !isPayNow && user.shopify_package_name === "ShopProY";
    const currentMonthly = !isPayNow && user.shopify_package_name === "ShopProM";
    const cardStyles = `w-full relative  rounded-[15px]`;
    const itemProps = {
        isRegFunnel,
        handleSelectPlan,
        isPayNow,
        fromNoSub
    }

    return (
        <div className="flex justify-end gap-[2px] max-lg:gap-4.5">
            <div className={`${currentMonthly ? "h-[106px] -top-[31px]" : ` -top-[45px] ${isPayNow ? "h-[180px] max-lg:h-[90px] max-lg:max-w-[146px]" : "h-[202px]"}`} ${hiddenCol === "monthly" ? "max-sm:max-w-[100%]" : "max-sm:hidden"} max-w-[290px] max-lg:px-0 bg-secondary max-lg:bg-transparent text-darkBlue px-[28.64px] pt-[53px] ${currentYearly ? "hidden" : ""} ${cardStyles}`}>
                <FooterItemContent {...shopifyPlans[0]} {...itemProps} />
            </div>
            <div className={`max-w-[348px] border-solid border-2 px-8 pt-[51px] ${currentYearly ? "h-[106px] -top-[31px] bg-secondary text-darkBlue border-secondary" : `pt-[51px] -top-[45px] max-lg:-top-[43px]  ${hiddenCol === "yearly" ? "max-sm:max-w-[100%]" : "max-sm:hidden"}  
                ${isPayNow ? "h-[180px] max-lg:max-w-[146px] max-lg:h-[90px]" : currentMonthly ? "h-[184px]" : "h-[202px]"} max-lg:px-0 max-lg:bg-transparent max-lg:text-darkBlue bg-darkBlue max-lg:border-none text-white  border-smoothGreen`} ${cardStyles}`}>
                <FooterItemContent {...shopifyPlans[1]} {...itemProps} />
            </div>
        </div>
    )
}

export default ShopifyPlanComparisonFooter