import { shopifyPlans } from "assets/data/plans/shopifyPlans";
import { FC, useRef, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { TSelectedPlan } from "types/plans";
import ShopifyPlan from "./Plan";

import NextPrevSlider from "components/Plans/NextPrevSlider";
import SwiperWrapper from "components/SwiperWrapper";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/pagination";

interface Props {
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    isRegFunnel?: boolean;
    isPayNow?: boolean;
    isResponsive?: boolean;
    onSlide?: (idx: number) => void;
    wrapperStyles?: string;
    sliderWrapperStyles?: string;
    contStyles?: string;
    nextPrevStyles?: string;
    planStyles?: string;
}

const plans = shopifyPlans
const ShopifyPlansCont: FC<Props> = ({ handleSelectPlan, isRegFunnel, isPayNow, planStyles, isResponsive, onSlide, wrapperStyles, nextPrevStyles }) => {
    const next = useRef(null);
    const prev = useRef(null);
    const [activeSlide, setActiveSlide] = useState(1);

    const onSlidePlan = (idx: number) => {
        if (onSlide) {
            onSlide(idx);
        }
        setActiveSlide(idx);
    }
    return (
        <div className={`w-full ${wrapperStyles}`}>
            <div className={`w-full relative overflow-x-hidden mt-6 ${isResponsive ? "min-[671px]:hidden block" : "hidden"} `}>
                <SwiperWrapper
                    onActiveChange={onSlidePlan}
                    next={next.current}
                    prev={prev.current}
                >
                    {plans.map((item) =>
                        <SwiperSlide className="h-auto max-w-[310px]" content="fit" key={item.id}>
                            <ShopifyPlan {...item} wrapperStyles={`${planStyles} ${(isPayNow && item.id === "monthly") ? "mt-[30px]" : ""}`} handleClick={handleSelectPlan} isRegFunnel={isRegFunnel} isPayNow={isPayNow} />
                        </SwiperSlide>)}
                </SwiperWrapper>
                {isResponsive ? (
                    <NextPrevSlider next={next} isShopify prev={prev} activeSlide={activeSlide} wrapperStyles={nextPrevStyles} />
                ) : ""}
            </div>

            <div className={`flex items-center gap-12 max-lg:gap-2 justify-center ${isResponsive ? "max-[670px]:hidden" : ""}`}>
                {plans.map((item) => <ShopifyPlan {...item} key={item.id} handleClick={handleSelectPlan} isRegFunnel={isRegFunnel} isPayNow={isPayNow} />)}
            </div>
        </div>
    )
}

export default ShopifyPlansCont